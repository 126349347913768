import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PartenairesPage = () => (
  <Layout>
    <SEO
      title="Partenaires - Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/a-propos-partenaires/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content my-auto">
            <h1>
              <i className="fal fa-camera-movie ml-2 mr-2"></i>Partenaires
            </h1>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row">
          <div className=" card card-orange col-lg-4">
            <div className="card-body">
              <h2 className="text-white">Institutions</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-8">
            <div className="card-body">
              <h3>Mairie de Paris</h3>
              <p>
                Paris, capitale du cinéma, est attaché à la diversité des écritures
                et des formes cinématographiques. La Mairie de Paris encourage la
                création, de la production (soutien aux courts métrages, accueil des
                tournages) à la salle de cinéma (soutien aux salles art et essai
                indépendantes, festivals, Forum des Images). Cette volonté politique
                s’inscrit dans une perspective d’éducation à l’image et d’aménagement
                du territoire. La Mission cinéma soutient différents dispositifs
                d’éducation au cinéma (des projections mais aussi des ateliers
                pratiques) en temps scolaire et en dehors du temps scolaire pour
                faire de chaque jeune Parisien un cinéphile.
              </p>
              <p>
                Délégué de la Mission Cinéma : <strong>Michel Gomez</strong>
                <br />
                <a href="mailto:missioncinema@paris.fr">missioncinema@paris.fr</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Rectorat de Paris</h3>
              <p>
                <strong>
                  Délégation Académique aux Arts et à la Culture Relais
                </strong>{' '}
                entre les partenaires culturels et les écoles, collèges, lycées
                parisiens, la Délégation Académique aux Arts et à la Culture
                (D.A.A.C.) est au service des enseignants, l’un de ses rôles étant
                donc d’aider à la réalisation d’actions concernant les dispositifs
                académiques ou hors dispositifs, au plus près du besoin des élèves.
              </p>
              <p>
                Délégué académique aux Arts et à la culture :&nbsp;
                <strong>Nathalie Berthon</strong>
                <br />
                <br />
                <a
                  href="http://www.ac-paris.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.ac-paris.fr
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Goethe Institut</h3>
              <p>
                Cinéallemand - Le Goethe Institut met à disposition des professeurs
                d’allemand le dispositif CInéallemand qui propose 3 ou 4 films
                allemands par an, projetés par les cinémas partenaires dans le cadre
                de séances scolaires.
              </p>
              <p>
                17 avenue d'Iéna 75116 Paris
                <br />
                <br />
                <a href="tel:0144439230">01 44 43 92 30</a>
                <a
                  href="https://www.goethe.de/ins/fr/fr/spr/unt/ver/kan/cin.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  En savoir plus
                </a>
              </p>
            </div>
          </div>
          <div className="card card-content col-lg-12">
            <div className="card-body">
              <h3>Institut Français du Portugal - Cinelíngua francesa</h3>
              <p>
                Cinelíngua francesa, dispositif créé en 2020 par le secteur culturel
                et audiovisuel de l'IFP en collaboration avec le secteur éducatif et
                linguistique, est une adaptation au Portugal de Cinélangues. Le
                dispositif Cinelíngua francesa, qui permet d'utiliser le cinéma comme
                outil d'enseignement et d'apprentissage de la langue française, a
                pour objectif d’attirer le public scolaire dans les salles de cinéma
                avec des films français récents et adaptés au jeune public et à
                travers un accompagnement pédagogique élaboré pour les professeurs et
                par des professeurs.
              </p>
              <p>
                <a
                  href="http://www.cinelinguafrancesa.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  En savoir plus
                </a>
              </p>
            </div>
          </div>

          <div className=" card card-orange col-lg-4">
            <div className="card-body text-white">
              <h2>Associations de professeurs de langue</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Association Française pour la diffusion de l’Espagnol</h3>
              <p>
                Créée en 1973 par la volonté conjointe des autorités espagnoles et
                françaises, l’Association Française pour la Diffusion de l’Espagnol
                (AFDE) contribue au développement en France de l’enseignement de la
                langue espagnole et à une meilleure connaissance des civilisations
                des pays hispanophones. Elle travaille en partenariat avec l’Office
                d’Éducation de l’Ambassade d’Espagne.
              </p>
              <p>
                <a href="tel:0146651614">01 46 65 16 14</a>
                <a href="mailto:afde.asso@wanadoo.fr">afde.asso@wanadoo.fr</a>
                <a
                  href="http://afde.blogspirit.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://afde.blogspirit.com
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>APIRP</h3>
              <p>
                L’Association des Professeurs d’Italien de la Région Parisienne
                existe depuis les années 1970. Elle a pour objet d’établir et de
                développer des liens de solidarité et d’amitié entre les italianistes
                de la région parisienne ainsi que de leur permettre d’examiner les
                problèmes spécifiques à leur discipline.
              </p>
              <p>
                <a href="tel:0146651614">01 46 65 16 14</a>
                <a href="mailto:afde.asso@wanadoo.fr">afde.asso@wanadoo.fr</a>
                <a
                  href="http://apirp.free.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://apirp.free.fr
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-orange col-lg-4">
            <div className="card-body text-white">
              <h2>Distributeurs</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Bodega Films</h3>
              <p>
                Distributeur indépendant de films« Art et Essai » de toutes
                nationalités et société d’édition DVD, spécialisé dans la découverte
                de jeunes réalisateurs de tous horizons & le cinéma Bollywood.
              </p>
              <p>
                8 bd Montmartre 75009 PARIS
                <br />
                <br />
                <a href="tel:0142248788">01 42 24 87 88</a>
                <a
                  href="http://www.bodegafilms.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.bodegafilms.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Carlotta Films</h3>
              <p>
                Carlotta Films, société de distribution de films en salles et éditeur
                DVD, a été créée en 1998 dans le but de valoriser le cinéma dit de
                patrimoine. Elle s’est dotée d’un pôle édition DVD en 2002. Carlotta
                Films a développé un catalogue de films dans lequel se distinguent
                les constituantes majeures de l’identité de la société : les cinémas
                américain, japonais, italien et jeune public.
              </p>
              <p>
                <a
                  href="http://www.carlottafilms.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.carlottafilms.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Epicentre Films</h3>
              <p>
                Depuis sa création Epicentre Films poursuit fidèlement une politique
                de distribution et de production qui est fermement attachée à
                soutenir le cinéma d’Art et Essai et qui cherche à diffuser des films
                exigeants, engagés, venus de tous les horizons, et qui s’articulent
                bien souvent autour d’une thématique de la quête identitaire.
              </p>
              <p>
                <a
                  href="http://www.epicentrefilms.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.epicentrefilms.com
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Equation</h3>
              <p>
                Equation Distribution propose des œuvres fortes, audacieuses,
                singulières, représentatives d’un certain état du monde et du talent
                de leurs auteurs respectifs, qu’elle a contribué à faire découvrir et
                qu’elle suit de film en film.
              </p>
              <p>
                <a
                  href="http://www.sdicine.fr/equation-distribution.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.sdicine.fr/equation-distribution.html
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Jour2Fête</h3>
              <p>
                Jour2Fête démarre son activité de distributeur de films en 2006, et
                l’édition DVD en 2008. Sa vocation est de faire découvrir à travers
                ses coups de coeur un cinéma d’auteur de tous les pays et des
                documentaires d’exceptions.
              </p>
              <p>
                <a
                  href="http://www.jour2fete.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.jour2fete.com
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Pyramide</h3>
              <p>
                Créé en 1989, Pyramide distribution défend depuis 25 ans des films
                d’auteurs du monde entier, aussi bien des grands noms que des jeunes
                auteurs que Pyramide s’attache à découvrir et accompagner. Aki
                Kaurismaki, Fatih Akin, Apichatpong Weerasethakul, Andrei
                Zviaguintsev, Céline Sciamma, Philippe Faucon, Alix Delaporte …
                autant de réalisateurs avec lesquels Pyramide a le bonheur de
                travailler.
              </p>
              <p>
                <a
                  href="http://www.pyramidefilms.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.pyramidefilms.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Swift Production</h3>
              <p>
                Depuis plus de 20 ans, la société Swift Productions a pour seule
                exigence l’acquisition et la distribution d’œuvres cinématographiques
                de qualité. Elle est présente sur quatre créneaux d’activités : la
                distribution de films en salles, l’édition dvd, la vente de
                programmes aux chaînes de télévision et la production de
                long-métrage.
              </p>
              <p>
                <a
                  href="http://www.swiftprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.swiftprod.com
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-orange col-lg-4">
            <div className="card-body text-white">
              <h2>Salles de cinéma</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Cinéma des Cinéastes </h3>
              <p>
                7, Avenue de Clichy 75017 Paris, M° Place Clichy
                <br />
                <br />
                Séances scolaires en matinée. 4 euros par élève (gratuit pour les
                accompagnateurs)
                <br />
                Contact: <strong>Sophie Fangain</strong>
              </p>
              <p>
                <a href="mailto:sfangain@larp.fr">sfangain@larp.fr</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>L'Arlequin</h3>
              <p>
                76, rue de Rennes 75006 Paris, M° St Sulpice <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Le Reflet Médicis</h3>
              <p>
                3, rue Champollion 75005 Paris, M° St Michel
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>L'Escurial</h3>
              <p>
                11, Bd du Port Royal 75013 Paris, M° Censier Daubenton
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Le Majestic Bastille</h3>
              <p>
                18, rue de Passy 75016 Paris, M° Passy
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Le Majestic Passy</h3>
              <p>
                4, Bd Richard Lenoir 75011 Paris M° Bastille
                <br />
                <br />
                Contact: <strong>Lena Nilly</strong>
              </p>
              <p>
                <a href="mailto:lnilly@dulaccinemas.com">lnilly@dulaccinemas.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Cinéma Luminor Hôtel de ville</h3>
              <p>20, rue du Temple, 75004 Paris</p>
              <p>
                <a
                  href="http://www.luminor-hoteldeville.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.luminor-hoteldeville.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Le Studio des Ursulines</h3>
              <p>
                10, rue des Ursulines, 75005 Paris, (RER : Luxembourg – M° Censier
                Daubenton)
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Les 7 Parnassiens</h3>
              <p>
                98, Boulevard du Montparnasse / 75017 Paris
                <br />
                Séances scolaires en matinée. Pour réserver : Ghislaine Masset
              </p>
              <p>
                <a href="tel:0142252825">01 42 25 28 25</a>
                <a href="mailto:ghislainemasset@free.fr">ghislainemasset@free.fr</a>
                <a
                  href="http://www.parnassiens.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.parnassiens.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Les 5 Caumartin</h3>
              <p>
                101, rue St Lazare / 75009 Paris
                <br />
                Séances scolaires en matinée. Pour réserver : Ghislaine Masset. Tarif
                groupe : 4 euros/élève (gratuit pour les accompagnateurs)
              </p>
              <p>
                <a href="tel:0142252825">01 42 25 28 25</a>
                <a href="mailto:ghislainemasset@free.fr">ghislainemasset@free.fr</a>
                <a
                  href="http://www.cinqcaumartin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.cinqcaumartin.com
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Cinéma L’archipel</h3>
              <p>17 boulevard de Strasbourg, 75010 Paris</p>
              <p>
                <a href="tel:0141901702">01 41 90 17 02</a>
                <a href="mailto:cinema@larchipel.net">cinema@larchipel.net</a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Les Studios Cinémas</h3>
              <p>2 Rue des Ursulines / 37000 Tours</p>
              <p>
                <a href="tel:0247202700">01 41 90 17 02</a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.studiocine.com/"
                >
                  http://www.studiocine.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-orange col-lg-4">
            <div className="card-body text-white">
              <h2>Festivals de cinéma</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Viva Mexico Rencontres cinématographiques</h3>
              <p>Le rendez-vous du cinéma mexicain en France.</p>
              <p>
                <a
                  href="www.viva-mexico-cinema.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.viva-mexico-cinema.org/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-4">
            <div className="card-body">
              <h3>Festival Enfances dans le monde</h3>
              <p>
                Chaque année, en novembre, à l’occasion de la Journée mondiale des
                droits de l’enfant, le festival de cinéma propose aux élèves de
                collège et lycée de découvrir des situations vécues par des enfants
                aux quatre coins de la planète. Un tour du monde des droits de
                l’enfant grâce à des documentaires de grande qualité, des histoires
                vraies et poignantes.
              </p>
              <p>
                <a
                  href="http://enfancesdanslemonde.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://enfancesdanslemonde.com/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Cinelatino</h3>
              <p>
                Depuis 23 ans, les Rencontres Cinémas d’Amérique Latine de Toulouse
                sont le carrefour européen de la création cinématographique
                latino-américaine. Toutes les formes de cinéma sont présentes au
                programme : longs-métrages de fiction, courts-métrages, documentaires
                … pour découvrir des auteurs émergents, des films du patrimoine et
                des thématiques culturelles, sociales ou environnementales. Dans ce
                cadre, l’ARCALT propose des séances scolaires, des accompagnements
                pédagogiques et des activités culturelles destinés aux collégiens et
                lycéens et à leurs professeurs.
              </p>
              <p>
                <a
                  href="http://www.cinelatino.com.fr/contenu/scolaires"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.cinelatino.com.fr/contenu/scolaires
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-content col-lg-6">
            <div className="card-body">
              <h3>Jangada</h3>
              <p>
                Rendez-vous incontournable des amants du 7e Art et des passionnés du
                Brésil, le Festival du Cinéma Brésilien, organisé par l’Association
                Jangada, est une véritable plateforme d’échange entre le Brésil et la
                France. Chaque année au mois de mai, il propose une programmation
                éclectique destinée au grand public et aux professionnels, qui permet
                aux parisiens de découvrir une filmographie contemporaine ainsi que
                les classiques du patrimoine cinématographique brésilien.
              </p>
              <p>
                <a
                  href="http://www.jangada.org/site/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.jangada.org/site/
                </a>
              </p>
            </div>
          </div>
          <div className=" card card-orange col-lg-3">
            <div className="card-body text-white">
              <h2>Presse</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-9">
            <div className="card-body">
              <h3>Vocable</h3>
              <p>
                Vocable, éditeur spécialisé dans l’apprentissage des langues propose
                des outils reconnus d’intérêt pédagogique et directement exploitables
                en classe : • les meilleurs articles de la presse internationale en
                V.O. avec la traduction des mots difficiles • des ressources audio,
                des vidéos et de nombreux tests en lien avec les articles du
                magazine. Existe en anglais, espagnol et allemand.
              </p>
              <p>
                <a
                  href="http://www.vocable.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.vocable.fr
                </a>
              </p>
            </div>
          </div>
          <div className="card card-orange col-lg-3">
            <div className="card-body text-white">
              <h2>Sites Web Cinéma</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-9">
            <div className="card-body">
              <h3>Cinespagne</h3>
              <p>
                Cinespagne.com relaye l’actualité du cinéma espagnol en France avec
                des critiques de films, des interviews, la couverture de festivals,
                des portraits de réalisateurs et d’interprètes, des analyses, des
                extraits et des bandes annonces… Le site est animé par une équipe de
                bénévoles hispanophiles et cinéphiles.
              </p>
              <p>
                <a
                  href="http://www.cinespagne.com/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.cinespagne.com/index.php
                </a>
                <a href="mailto:contact@cinespagne.com">contact@cinespagne.com</a>
              </p>
            </div>
          </div>
          <div className=" card card-orange col-lg-3">
            <div className="card-body text-white">
              <h2>Associations</h2>
            </div>
          </div>
          <div className=" card card-content col-lg-9">
            <div className="card-body">
              <h3>Espagnolas en París</h3>
              <p>
                Espagnolas en París, association à but non lucratif, fait connaître
                en France le meilleur cinéma d’auteur espagnol, par des projections
                de films inédits, toujours en présence des équipes et dans une
                ambiance joyeuse.
              </p>
              <p>
                <a href="www.gnolas.org" target="_blank" rel="noopener noreferrer">
                  www.gnolas.org
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PartenairesPage;
